import './style/style.css'
import Navbar from "./components/navbar";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home"
import Clients from "./pages/Clients"
import Bookings from "./pages/Bookings"
import Accounting from "./pages/Accounting"
import Settings from "./pages/Settings"
import Signin from "./pages/Signin";
import { AuthContextProvider } from "./context/AuthContext";
import Config from './pages/Config';

//A 404 error is not really necessary, because this project works in root, with a SPA (Single Page Application) scheme; 
// This is comment fot test CI DEV AND MAIN
function App() {
  return (
    <>
      <AuthContextProvider>
        <Navbar />
        <Routes>
          <Route path="/clients" element={<Clients />} />
          {/* <Route path="/Bookings" element={<Bookings />} /> */}
          {/* <Route path="/accounting" element={<Accounting />} /> */}
          {/* <Route path="/settings" element={<Settings />} /> */}
          <Route path="/config" element={<Config />} />
          <Route path="/signin" element={<Signin />} />
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/" element={<Clients />} />
        </Routes>
      </AuthContextProvider>
    </>
  );
}

export default App;


