import { initializeApp } from "firebase/app"
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyCByksR_BKHm1lZIDPs6ZAUwQJHUDzuiJ0",
    authDomain: "crm-t49.firebaseapp.com",
    projectId: "crm-t49",
    storageBucket: "crm-t49.appspot.com",
    messagingSenderId: "497462423907",
    appId: "1:497462423907:web:5a6746e52feccc115a2356",
    measurementId: "G-32QMS47PTD"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

