import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select'
import Swal from "sweetalert2";
import '../../style/formClient.css'
import { getDataLeadStatus } from '../../services/servicesConfigLeadStatus';
import { getDataLeadOwner } from '../../services/configLeadOwner';
import { getDataRequestType } from '../../services/configRequestType';
import { getDataVehicle } from '../../services/configVehicle';
import { FaInfoCircle } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { BiAddToQueue } from "react-icons/bi";
import { auth } from '../../firebase';
import { numtoDate, formatDate } from '../../services/tools';

function FormClient({ title, textButton, action, lastData, setModal }) {

    const [id, setId] = useState("");
    const [client, setClient] = useState("");
    const [organisation, setOrganisation] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [source, setSource] = useState("");
    const [status, setStatus] = useState({ value: '', label: '' });
    const [owner, setOwner] = useState({ value: '', label: '' });
    const [date, setDate] = useState("");
    const [modified, setModified] = useState("");
    const [value, setValue] = useState("");
    //const [requested, setRequested] = useState("");
    const [requested, setRequested] = useState([]);
    const [historical, setHistorical] = useState([]);
    const [nop, setNop] = useState("");
    const [request_type, setRequestType] = useState({ value: '', label: '' });
    const [feedback, setFeedback] = useState("");
    // const [return_travel, setReturnTravel] = useState("");
    const [vehicle, setVehicle] = useState({ value: '', label: '' });

    // This is necessary to fill in the selects and reuse the code from the config lead status services.
    const [leadStatusListAux, setLeadStatusListAux] = useState([]);
    const [leadStatusList, setLeadStatusList] = useState([{ value: '', label: '' }]);

    // This is necessary to fill in the selects and reuse the code from the config lead owner services.
    const [leadOwnerListAux, setLeadOwnerListAux] = useState([]);
    const [leadOwnerList, setLeadOwnerList] = useState([{ value: '', label: '' }]);

    // This is necessary to fill in the selects and reuse the code from the config request type services.
    const [requestTypeListAux, setRequestTypeListAux] = useState([]);
    const [requestTypeList, setRequestTypeList] = useState([{ value: '', label: '' }]);

    // This is necessary to fill in the selects and reuse the code from the config request type services.
    const [vehicleListAux, setvehicleListAux] = useState([]);
    const [vehicleList, setvehicleList] = useState([{ value: '', label: '' }]);

    //Use by Lead Status List
    useEffect(() => {
        getDataLeadStatus(setLeadStatusListAux);
    }, []);

    //Use by Lead Status List
    useEffect(() => {
        if (leadStatusListAux?.rows?.length > 0) {
            var aux = [];
            leadStatusListAux?.rows?.forEach(element => {
                aux.push({ value: element.value, label: element.label });
            });
            setLeadStatusList(aux);
        }
    }, [leadStatusListAux]);

    //Use by Lead Owner List
    useEffect(() => {
        getDataLeadOwner(setLeadOwnerListAux);
    }, []);

    //Use by Lead Owner List
    useEffect(() => {
        if (leadOwnerListAux?.rows?.length > 0) {
            var aux = [];
            leadOwnerListAux?.rows?.forEach(element => {
                aux.push({ value: element.value, label: element.label });
            });
            setLeadOwnerList(aux);
        }
    }, [leadOwnerListAux]);

    //Use by Request Type List
    useEffect(() => {
        getDataRequestType(setRequestTypeListAux);
    }, []);

    //Use by request type List
    useEffect(() => {
        if (requestTypeListAux?.rows?.length > 0) {
            var aux = [];
            requestTypeListAux?.rows?.forEach(element => {
                aux.push({ value: element.value, label: element.label });
            });
            setRequestTypeList(aux);
        }
    }, [requestTypeListAux]);

    //Use by recommended vehicle List
    useEffect(() => {
        getDataVehicle(setvehicleListAux);
    }, []);

    //Use by recommended vehicle List
    useEffect(() => {
        if (vehicleListAux?.rows?.length > 0) {
            var aux = [];
            vehicleListAux?.rows?.forEach(element => {
                aux.push({ value: element.value, label: element.label });
            });
            setvehicleList(aux);
        }
    }, [vehicleListAux]);


    useEffect(() => {
        if (lastData) {
            setId(lastData.id);
            setClient(lastData.client);
            setOrganisation(lastData.organisation)
            setEmail(lastData.email);
            setPhone(lastData.phone);
            setSource(lastData.source);

            setStatus({ value: lastData.status, label: lastData.status });
            setOwner({ value: lastData.owner, label: lastData.owner });

            setDate(lastData.date);
            setModified(lastData.modified);
            setValue(lastData.value);

            //If this was exported, it can no longer be an array, so it needs to be patched.

            if (Array.isArray(requested)) {
                let data = [...requested];
                //if(lastData?.requested?.length > 0){
                    for (let i = 0; lastData?.requested?.length > i; i++) {
                        data[i] = lastData.requested[i];
                    }
                    setRequested(data);
                //}
            } else {
                let data = [];
                data[0] = lastData.requested[0];
                setRequested(data);
            }

            let newHistorical = [];
            for (let i = 0; lastData?.historical_matriz?.length > i; i++) {
                newHistorical[i] = lastData.historical_matriz[i];
            }

            setHistorical(newHistorical);

            setNop(lastData.nop);

            setRequestType({ value: lastData.request_type, label: lastData.request_type });

            setFeedback(lastData.feedback);
            // setReturnTravel(lastData.return_travel);
            setVehicle({ value: lastData.vehicle, label: lastData.vehicle });

        }
    }, [lastData])

    const addInputField = (e) => {
        e.preventDefault();
        setRequested([...requested, ""]);
    }

    const handleInputChange = (index, e) => {
        let data = [...requested];
        data[index] = e.target.value;
        setRequested(data);
    }

    const removeFields = (index) => {
        let data = [...requested];
        data.splice(index, 1)
        setRequested(data)
    }

    const leadStatusHistorical = () => {
        Swal.fire({
            title: '<strong>Historical</strong>',
            icon: 'info',
            html: `                              
          <table class="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col">New</th>
                <th scope="col">Updated</th>
                <th scope="col">Change by</th>
              </tr>
            </thead>
            <tbody>
              ${getHistorical()}
            </tbody>
          </table>`,
            showCloseButton: true,
            confirmButtonColor: "#173d38"
        })
    }

    const getHistorical = () => {
        var tbody = "";
        if (historical?.length > 0) {
            historical.forEach(element => {
                tbody = tbody + `<tr><td>${element.newValue}</td><td>${element.updated}</td><td>${element.change}</td></tr>`
            });
        } else {
            tbody = "<tr><td colspan='4'>Without results</td></tr>";
        }
        return tbody;
    }


    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="formFullName">
                        <div className="d-inline-flex">
                            <Form.Label>Client</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter full name"
                            value={client}
                            onChange={(e) => setClient(e.target.value)}
                        />
                        {/* <Form.Text className="text-muted">
                            Need tooltip
                        </Form.Text> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formOrganisation">
                        <Form.Label>Organization</Form.Label>
                        <Form.Control type="text" placeholder="Enter Organization"
                            value={organisation}
                            onChange={(e) => setOrganisation(e.target.value)}
                        />
                        {/* <Form.Text className="text-muted">
                            Need tooltip
                        </Form.Text> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                        <div className="d-inline-flex">
                            <Form.Label>Email Address</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <Form.Control type="email" placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                        {/* <Form.Text className="text-muted">
                            Need tooltip
                        </Form.Text> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPhoneNumber">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="text" placeholder="Enter Phone Number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        {/* <Form.Text className="text-muted">
                            Need tooltip
                        </Form.Text> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formLeadStatus">
                        <div className="d-inline-flex">
                            <Form.Label><FaInfoCircle className={'mb-1 color-green-info  p-0 rounded-circle'} onClick={leadStatusHistorical} data-toggle="tooltip" data-placement="top" title="Shows the Lead Status host"></FaInfoCircle>Lead Status</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <Select options={leadStatusList} value={status}
                            onChange={(e) => setStatus(e)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formLeadOwner">
                        <div className="d-inline-flex">
                            <Form.Label>Lead Owner</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <Select options={leadOwnerList} value={owner}
                            onChange={(e) => setOwner(e)} />
                        {/* <Form.Text className="text-muted">
                            Need tooltip
                        </Form.Text> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formContactDate">
                        <div className="d-inline-flex">
                            <Form.Label>Contact Date</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <Form.Control type="date" placeholder="Enter Contact Date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                        {/* <Form.Text className="text-muted">
                            Need tooltip
                        </Form.Text> */}
                    </Form.Group>

                    {/* <Form.Group className="mb-3" controlId="formLastModified">
                        <Form.Label>Last Modified</Form.Label>
                        <Form.Control type="date" placeholder="Enter Last Modified"
                            value={modified}
                            onChange={(e) => setModified(e.target.value)}
                        /> */}
                    {/* <Form.Text className="text-muted">
                            Need tooltip
                        </Form.Text> */}
                    {/* </Form.Group> */}

                </div>
                <div className='col-md-6'>

                    <Form.Group className="mb-3">
                        <div className='flex d-flex justify-content-between mb-2'>
                            <Form.Label>Shuttle Date requested</Form.Label>
                            <Button className={'color-green rounded-circle'} onClick={addInputField}><BiAddToQueue></BiAddToQueue></Button>
                        </div>
                        {requested.map((request, index) => (
                            <div key={index} className="input-group mb-3">
                                <span className="input-group-text">{`Shuttle ${index + 1}`}</span>
                                <Form.Control type="date" className="form-control" placeholder="Enter Date Requested"
                                    id={`requested-${index + 1}`}
                                    value={request}
                                    onChange={(e) => handleInputChange(index, e)}
                                />
                                <Button className={'input-group-text'} style={{ marginLeft: 3, background: "#f4673c" }} onClick={() => removeFields(index)}><AiOutlineDelete ></AiOutlineDelete></Button>
                            </div>
                        ))}
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="formReturnTravel">
                        <Form.Label>Return Travel Date</Form.Label>
                        <Form.Control type="date" placeholder="Enter Return Travel Date"
                            value={return_travel}
                            onChange={(e) => setReturnTravel(e.target.value)}
                        /> */}
                    {/* <Form.Text className="text-muted">
                            Need tooltip
                        </Form.Text> */}
                    {/* </Form.Group> */}

                    <Form.Group className="mb-3" controlId="formRequestType">
                        <div className="d-inline-flex">
                            <Form.Label>Request Type</Form.Label>
                            <h5 className="required">*</h5>
                        </div>
                        <Select options={requestTypeList} value={request_type}
                            onChange={(e) => setRequestType(e)} />
                        {/* <Form.Text className="text-muted">
                            Need tooltip
                        </Form.Text> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formRequestType">
                        <Form.Label>Recommended Vehicle</Form.Label>
                        <Select options={vehicleList} value={vehicle}
                            onChange={(e) => setVehicle(e)} />
                        {/* <Form.Text className="text-muted">
                            Need tooltip
                        </Form.Text> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formLeadSoruce">
                        <Form.Label>Lead Source</Form.Label>
                        <Form.Control type="text" placeholder="Enter Source"
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                        />
                        {/* <Form.Text className="text-muted">
                            Need tooltip
                        </Form.Text> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formNOP">
                        <Form.Label>Number of People</Form.Label>
                        <Form.Control type="text" placeholder="Enter number of people"
                            value={nop}
                            onChange={(e) => setNop(e.target.value)}
                        />
                        {/* <Form.Text className="text-muted">
                            Need tooltip
                        </Form.Text> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formValue">
                        <Form.Label>Value</Form.Label>
                        <Form.Control type="text" placeholder="Enter value"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                        {/* <Form.Text className="text-muted">
                            Need tooltip
                        </Form.Text> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formCustomerFeedback">
                        <Form.Label>Customer Feedback</Form.Label>
                        <Form.Control type="text" placeholder="Enter Customer Feedback"
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                        />
                        {/* <Form.Text className="text-muted">
                            Need tooltip
                        </Form.Text> */}
                    </Form.Group>
                </div>
                <h5 className="required">All fields with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                <Button variant="primary" className='button-save' onClick={() => {
                    var arrayHistorical = [];
                    let historical_matriz = historical;
                    if(lastData !== null){
                        if (lastData?.status !== status.value) {
                            let today = new Date();
                            // get date
                            let now = today.toLocaleString();
                            let newHistorical = {
                                newValue: status.value,
                                oldValue: lastData.status,
                                updated: now,
                                change: auth.currentUser.displayName,
                            }

                            //I iterated it like this, because if the iterator is an object, for some reason it throws me an error
                            historical.forEach(element => {
                                arrayHistorical.push(element);
                            });
                            arrayHistorical.push(newHistorical);
                        }
                        historical_matriz = (lastData.status !== status.value) ? arrayHistorical : historical;

                    } else{
                        let today = new Date();
                            // get date
                            let now = today.toLocaleString();
                            let newHistorical = {
                                newValue: status.value,
                                oldValue: "",
                                updated: now,
                                change: auth.currentUser.displayName,
                            }

                            //I iterated it like this, because if the iterator is an object, for some reason it throws me an error
                            historical.forEach(element => {
                                arrayHistorical.push(element);
                            });
                            arrayHistorical.push(newHistorical);
                            historical_matriz = arrayHistorical;
                    }
                    /*
                    If the arrayhistorical object has changed, then put the new one, if not 
                    the state, this is because within the same instance, if I update the state, 
                    I drop the thread to get the current value, because I get the prize value, 
                    so this way I can cheat it
                    */

                    var data = {
                        id,
                        client,
                        organisation,
                        email,
                        phone,
                        source,
                        status,
                        owner,
                        date,
                        modified,
                        value,
                        requested,
                        nop,
                        request_type,
                        feedback,
                        vehicle,
                        historical_matriz
                    };

                    if (client !== '' && email !== '' && status.value !== '' && owner.value !== '' && date !== '' && request_type.value !== '') {
                        action(data);
                    } else {
                        Swal.fire(
                            'Error!', //Title
                            'All fields with (*) are required', //Text
                            'error' //Constants = success, error, warning 
                        )
                    }
                }
                }>
                    Save
                </Button>
            </div>
        </form>
    );
}

export default FormClient;