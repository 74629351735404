import { query, collection, getDocs, doc, setDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { auth, db } from '../firebase'
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { server } from './serverConfig';

export const addDataRequestType = async (data, message = false, del = false) => {
  try {
    var id = uuid();
    const docRef = doc(
      db,
      del ? (server === 'clients' ? "plan_1_delete_911_request_type" : 'dev_delete_911_request_type') : server + "_request_type",
      id
    );
    const userId = auth.currentUser;
    if (userId !== null) {
      data['created_by_id'] = userId.uid;
      data['created_by_email'] = userId.email;
      data['created_by_name'] = userId.displayName;
      data['id'] = id;

      if (del) {
        data['deleted_by_id'] = userId.uid;
        data['deleted_by_email'] = userId.email;
        data['deleted_by_name'] = userId.displayName;
      }

      await setDoc(docRef, data);

      if (message) {
        Swal.fire({
          icon: "success",
          title: "Added",
          text: "The request type was created successfully",
          confirmButtonColor: '#173d38',
          confirmButtonText: "Ok",
        });
      }
    }
  } catch (error) {
    alert(error);
  }
}

export const editDataRequestType = async (edit) => {
  try {
    const docRef = doc(db, server + "_request_type", edit.id);

    const userId = auth.currentUser;
    edit['updated_by_id'] = userId.uid;
    edit['updated_by_email'] = userId.email;
    edit['updated_by_name'] = userId.displayName;

    await updateDoc(docRef,
      edit
    );
    Swal.fire({
      icon: "success",
      title: "Edited",
      text: "The request type was edited successfully",
      confirmButtonColor: '#173d38',
      confirmButtonText: "Ok",
    });
  } catch (error) {
    console.log(error)
  }
}

export const getDataRequestType = async (setdata, setPending = null) => {
  try {
    if (auth.currentUser != null) {
      const q = query(collection(db, server + "_request_type"));
      const querySnapshot = await getDocs(q);

      setdata({ rows: [] });

      var array = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        array.push(data);
      });
      setdata({
        rows: array
      });

      if (setPending) {
        setPending(false);
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const deleteDataRequestType = async (element) => {
  try {
    var element_duplicated = Object.assign({}, element);
    await addDataRequestType(element_duplicated, false, true);

    const docRef = doc(db, server + "_request_type", element.id);
    await deleteDoc(docRef);
  } catch (error) {
    console.log(error)
  }
}
