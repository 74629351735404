import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from "sweetalert2";
import '../../../style/formClient.css'

function FormConfigLeadStatus({ title, action, lastData, setModal }) {

    const [id, setId] = useState("");
    const [label, setLabel] = useState("");

    useEffect(() => {
        if (lastData) {
            setId(lastData.id);
            setLabel(lastData.label);
            setLabel(lastData.value);
        }
    }, [lastData])

    const keypress = (e) => {
        if(e.keyCode === 13) { 
            e.preventDefault();
      }
    }

    return (
        <form>
            <h2 className='title text-center'>{title}</h2>
            <div className='row'>
                <div className='col-md-12'>
                    <Form.Group className="mb-3" controlId="formFullName">
                        <div className="d-inline-flex">
                            <Form.Label>Title</Form.Label>
                            <h5 className="required">*</h5>
                        </div>

                        <Form.Control type="text" placeholder="Enter Label"
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                            onKeyDown={(e) => keypress(e)}
                        />
                    </Form.Group>
                </div>
                <h5 className="required">All fields with (*) are required</h5>
            </div>
            <div className='footer-buttons'>
                <Button variant='danger' className='button-cancel' onClick={() => { setModal(false) }}>Cancel</Button>
                <Button variant="primary" className='button-save' onClick={() => {
                    var value = label;
                    var data = {
                        id,
                        label,
                        value
                    };

                    if (label !== '') {
                        action(data);
                    } else {
                        Swal.fire(
                            'Error!', //This is the title
                            'All fields with (*) are required', //This is the text
                            'error' //These are constants = success, error, warning 
                        )
                    }
                }
                }>
                    Save
                </Button>
            </div>
        </form>
    );
}

export default FormConfigLeadStatus;