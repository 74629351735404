import React, { useEffect } from 'react';
import TableConfigLeadStatus from "../components/tableForConfig/leadStatus/tableConfigLeadStatus";
import TableConfigLeadOwner from "../components/tableForConfig/leadOwner/tableConfig";
import TableConfigRequestType from "../components/tableForConfig/requestType/tableConfig";
import TableConfigVehicle from "../components/tableForConfig/vehicle/tableConfig";
import { useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from '../firebase';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Accordion from 'react-bootstrap/Accordion';
import 'react-tabs/style/react-tabs.css';

import '../style/config.css'

const idRole = 'config';

const Config = () => {
  const navigate = useNavigate();

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!user) navigate("/signin");
  }, [user, loading]);

  return (
    <div className="container">
      <h1>System settings<br></br><br></br></h1>
      <div className='row'>
        <div className='col-12'>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Dynamic lists</Accordion.Header>
              <Accordion.Body>
                <Tabs>
                  <TabList>
                    <Tab>Lead Status List</Tab>
                    <Tab>Lead Owner List</Tab>
                    <Tab>Request Type List</Tab>
                    <Tab>Recommended Vehicle List</Tab>
                  </TabList>

                  <TabPanel>
                    <TableConfigLeadStatus></TableConfigLeadStatus>
                  </TabPanel>
                  <TabPanel>
                    <TableConfigLeadOwner></TableConfigLeadOwner>
                  </TabPanel>
                  <TabPanel>
                    <TableConfigRequestType></TableConfigRequestType>
                  </TabPanel>
                  <TabPanel>
                    <TableConfigVehicle></TableConfigVehicle>
                  </TabPanel>
                </Tabs>
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="1">
              <Accordion.Header>coming soon ****</Accordion.Header>
              <Accordion.Body>
                coming soon ****
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export default Config