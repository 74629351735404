import Button from "../components/button";
import { FiEdit2 } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";

function columns(simplified = true, editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row?.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Client',
      selector: row => row?.client,
      sortable: true,
    },
    {
      name: 'Organization',
      selector: row => row?.organisation,
      sortable: true,
      omit: simplified
    },
    {
      name: 'Email Address',
      selector: row => row?.email,
      sortable: true,
    },
    {
      name: 'Phone Number',
      selector: row => row?.phone,
      sortable: true,
    },
    {
      name: 'Lead Source',
      selector: row => row?.source,
      sortable: true,
      omit: simplified
    },
    {
      name: 'Lead Status',
      selector: row => row?.status,
      sortable: true,
      omit: simplified
    },
    {
      name: 'Lead Owner',
      selector: row => row?.owner,
      sortable: true,
      omit: simplified
    },
    {
      name: 'Contact Date',
      selector: row => row?.date,
      sortable: true,
    },
    {
      name: 'Requested Date',
      selector: row => row?.requested?.[0],
      sortable: true,
    },
    {
      name: 'Last Modified',
      selector: row => row?.modified,
      sortable: true,
      omit: simplified
    },
    {
      name: 'Value',
      selector: row => row?.value,
      sortable: true,
      omit: simplified
    },
    {
      name: 'Number of People',
      selector: row => row?.nop,
      sortable: true,
      omit: simplified
    },
    {
      name: 'Request Type',
      selector: row => row?.request_type,
      sortable: true,
    },
    {
      name: 'Customer Feedback',
      selector: row => row?.feedback,
      sortable: true,
      omit: simplified
    },
    {
      name: 'Recommended Vehicle',
      selector: row => row?.vehicle,
      sortable: true,
      omit: simplified
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}
function columns_excel() {
  var header = [{
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "id",
    title: "Id",
    width: "50px",
    id: "_0_column",
    isAccessible: true,
    ariaColumnIndex: 1,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "client",
    title: "Client",
    width: "350px",
    id: "_1_column",
    isAccessible: true,
    ariaColumnIndex: 2,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "organisation",
    title: "Organization",
    id: "_2_column",
    isAccessible: true,
    ariaColumnIndex: 3,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "email",
    title: "Email Address",
    id: "_3_column",
    isAccessible: true,
    ariaColumnIndex: 4,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "phone",
    title: "Phone Number",
    id: "_4_column",
    isAccessible: true,
    ariaColumnIndex: 5,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "source",
    title: "Lead Source",
    id: "_5_column",
    isAccessible: true,
    ariaColumnIndex: 6,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "status",
    title: "Lead Status",
    id: "_6_column",
    isAccessible: true,
    ariaColumnIndex: 7,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "owner",
    title: "Lead Owner",
    id: "_7_column",
    isAccessible: true,
    ariaColumnIndex: 8,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "date",
    title: "Contact Date",
    id: "_8_column",
    isAccessible: true,
    ariaColumnIndex: 9,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "modified",
    title: "Last Modified",
    id: "_9_column",
    isAccessible: true,
    ariaColumnIndex: 10,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "value",
    title: "Value",
    id: "_10_column",
    isAccessible: true,
    ariaColumnIndex: 11,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "requested[0]",
    title: "Date Requested",
    id: "_11_column",
    isAccessible: true,
    ariaColumnIndex: 12,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "nop",
    title: "Number of People",
    id: "_12_column",
    isAccessible: true,
    ariaColumnIndex: 13,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "request_type",
    title: "Request Type",
    id: "_13_column",
    isAccessible: true,
    ariaColumnIndex: 14,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "feedback",
    title: "Customer Feedback",
    id: "_14_column",
    isAccessible: true,
    ariaColumnIndex: 15,
  },
  {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true,
    field: "vehicle",
    title: "Recommended Vehicle",
    id: "_15_column",
    isAccessible: true,
    ariaColumnIndex: 16,
  }
 ];
 return header;
}

function columnsLeadConfig(editObj = null, deleteObj = null) {
  var header = [
    {
      name: 'Id',
      selector: row => row.id,
      sortable: true,
      omit: true
    },
    {
      name: 'Title',
      selector: row => row.label,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => <div className="button-actions"><Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => { editObj(row.id) }}><FiEdit2></FiEdit2></Button>  <Button className="color-red btn btn-outline-secondary p-2 mx-2" onClick={() => { deleteObj(row.id) }}><AiOutlineDelete></AiOutlineDelete></Button> </div>
    }
  ]
  return header;
}

const leadStatus = [
  { value: 'LeadCreated', label: 'LeadCreated' },
  { value: 'QuoteSent', label: 'QuoteSent' },
  { value: 'Emailed', label: 'Emailed' },
  { value: 'FU:Phone', label: 'FU:Phone' },
  { value: 'LeftMessage', label: 'LeftMessage' },
  { value: 'FU:Email1', label: 'FU:Email1' },
  { value: 'FU:Email2', label: 'FU:Email2' },
  { value: 'NotBooked:Comp', label: 'NotBooked:Comp' },
  { value: 'NotBooked:Price', label: 'NotBooked:Price' },
  { value: 'NotBooked:NR', label: 'NotBooked:NR' },
  { value: 'NotBooked:FA', label: 'NotBooked:FA' },
  { value: 'Booked', label: 'Booked' },
]

const leadOwner = [
  { value: 'Jon', label: 'Jon' },
  { value: 'Mark', label: 'Mark' },
  { value: 'Joel', label: 'Joel' },
  { value: 'Ellen', label: 'Ellen' },
  { value: 'Dee', label: 'Dee' },
]

const requestType = [
  { value: 'P:Wedding', label: 'P:Wedding' },
  { value: 'P:Charter', label: 'P:Charter' },
  { value: 'P:Party', label: 'P:Party' },
  { value: 'YYC', label: 'YYC' },
  { value: 'YXC', label: 'YXC' },
]

const vehicles = [
  { value: 'Van13', label: 'Van13' },
  { value: 'Van14', label: 'Van14' },
  { value: 'Bus32', label: 'Bus32' },
  { value: 'Bus48', label: 'Bus48' },
]

function numtoDate(numDates, isTrue = true) {
  var to1900 = isTrue ? 25567 + 1 : 25567;
  // 86400 is the number of seconds in a day, so we multiply by 1000 to get milliseconds.
  return new Date((numDates - to1900) * 86400 * 1000);
}
const monthNames = [
  "January", "February", "March", "April", "May", "June", "July",
  "August", "September", "October", "November", "December"
];
const dayOfWeekNames = [
  "Sunday", "Monday", "Tuesday",
  "Wednesday", "Thursday", "Friday", "Saturday"
];
function formatDate(date, patternStr) {
  try {
    if (!patternStr) {
      patternStr = 'M/d/yyyy';
    }
    var day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear(),
      hour = date.getHours(),
      minute = date.getMinutes(),
      second = date.getSeconds(),
      miliseconds = date.getMilliseconds(),
      h = hour % 12,
      hh = twoDigitPad(h),
      HH = twoDigitPad(hour),
      mm = twoDigitPad(minute),
      ss = twoDigitPad(second),
      aaa = hour < 12 ? 'AM' : 'PM',
      EEEE = dayOfWeekNames[date.getDay()],
      EEE = EEEE.substr(0, 3),
      dd = twoDigitPad(day),
      M = month + 1,
      MM = twoDigitPad(M),
      MMMM = monthNames[month],
      MMM = MMMM.substr(0, 3),
      yyyy = year + "",
      yy = yyyy.substr(2, 2)
      ;
    // checks to see if month name will be used
    patternStr = patternStr
      .replace('hh', hh).replace('h', h)
      .replace('HH', HH).replace('H', hour)
      .replace('mm', mm).replace('m', minute)
      .replace('ss', ss).replace('s', second)
      .replace('S', miliseconds)
      .replace('dd', dd).replace('d', day)

      .replace('EEEE', EEEE).replace('EEE', EEE)
      .replace('yyyy', yyyy)
      .replace('yy', yy)
      .replace('aaa', aaa);
    if (patternStr.indexOf('MMM') > -1) {
      patternStr = patternStr
        .replace('MMMM', MMMM)
        .replace('MMM', MMM);
    }
    else {
      patternStr = patternStr
        .replace('MM', MM)
        .replace('M', M);
    }
    return patternStr;
  } catch (error) {

  }
}
function twoDigitPad(num) {
  return num < 10 ? "0" + num : num;
}


export { columns, columns_excel, columnsLeadConfig, leadStatus, leadOwner, requestType, vehicles, numtoDate, monthNames, dayOfWeekNames, formatDate, twoDigitPad }