import { useContext, createContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { auth } from '../firebase';
import Swal from "sweetalert2";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();

    signInWithRedirect(auth, provider)
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });

  };

  const logOut = () => {
    signOut(auth);
    //Aqui falta un redireccionar
  }

  useEffect(() => {
    if (user != null) {
      if (user?.email === "anthonymmarinbolivar@gmail.com") { //Delete in the future
      }
      else if (user?.email?.split('@')[1] !== "t49.ca") {
        setUser(null);
        signOut(auth);

        Swal.fire({
          title: 'Access denied',
          text: 'Sorry, the email you have entered is not authorized.',
          icon: 'error',
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonColor: '#173d38',
          confirmButtonText: 'CONTINUE'
        }).then(() => {
          window.location.href = '/signin'; //This should be changed to navigate ('/');
        })
      }
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ googleSignIn, logOut, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};