import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import imgw from '../assets/Word_Logo_Color_Cream.png'
import logo from '../assets/Logo_Icon.png'
import '../style/style.css'
import '../style/navbar.css'
import Dropdown from 'react-bootstrap/Dropdown';

import React from 'react';
import { UserAuth } from '../context/AuthContext';

const Navbars = () => {
  const { user, logOut } = UserAuth();
  let imageName = "https://via.placeholder.com/50/168a7a/FFFFFF/?text=T49";
  if(user != null){
    imageName = "https://via.placeholder.com/50/12675c/FFFFFF/?text=" + user?.displayName.charAt(0);// 173d38
  }
   
  const handleSignOut = async () => {
    try {
      await logOut()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Navbar className="navbar">
        <Container className='text-center'>
          <Navbar.Brand>
            <img className="logo" src={logo}></img>
          </Navbar.Brand>
        </Container>
        <Container>
          <Navbar.Brand>
            <img className="imag" src={imgw}></img>
          </Navbar.Brand>
        </Container>
        <Container>
          {user?.photoURL ? (
            <Dropdown className='m-auto'>
              <Dropdown.Toggle id="dropdown-split-basic" variant="null">
                <h5 className='mr-auto username'>{user?.displayName}</h5>
                <img className="nav-avatar" src={imageName}></img>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/****** This is wrong, you have to change it *****/}
                {/* <Dropdown.Item href="/home">Home</Dropdown.Item> */}
                <Dropdown.Item href="/clients">Clients</Dropdown.Item>
                {/* <Dropdown.Item href="/bookings">Bookings</Dropdown.Item> */}
                {/* <Dropdown.Item href="/accounting">Accounting</Dropdown.Item> */}
                {/* <Dropdown.Item href="/settings">Settings</Dropdown.Item> */}
                <Dropdown.Item href="/config">Settings</Dropdown.Item>
                <Dropdown.Item onClick={handleSignOut} >Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ''
          )}
        </Container>
      </Navbar>
      <br />
    </>
  );
}

export default Navbars;