import React, { useEffect } from 'react';
import Table from "../components/tableClient/tableClient";
import { useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from '../firebase';
const idRole = 'clients';

const Clients = () => {
  const navigate = useNavigate();

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user) navigate("/signin");
  }, [user, loading]);

  return (
    <div className="container">
      <Table></Table>
    </div>
  )
}

export default Clients