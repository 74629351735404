import { useEffect, useState, useMemo, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { columns, columns_excel } from '../../services/tools';
import Button from '../button';
import ModalC from '../modal';
import FormClient from './formClient';
import Swal from "sweetalert2";
import { addData, fileHandler, getData, deleteData, editData } from '../../services/services';
import { UserAuth } from '../../context/AuthContext';
import { Input } from 'antd';
import '../../style/table.css'
import Form from 'react-bootstrap/Form';
import { ExcelExport } from "@progress/kendo-react-excel-export";

function TableClient() {
  const [pending, setPending] = useState(true);
  const [data, setData] = useState({ rows: [] });
  const [modal, setModal] = useState(false);
  const [selects, setSelects] = useState([]);
  const [edit, setEdit] = useState(null);
  const [titleModal, setTitleModal] = useState('');
  const [expand, setExpand] = useState(true);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { user } = UserAuth();

  const _export = useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(data.rows, columns_excel());
    }
  };

  useEffect(() => {
    if (user != null) {
      getData(setData, setPending);

    }
  }, [user]);

  const handleChange = ({ selectedRows }) => {
    setSelects(selectedRows);
  };

  const action = (data) => {
    handleClearRows();
    data['status'] = data['status'] ? data['status'].value : "";
    data['owner'] = data['owner'] ? data['owner'].value : "";
    data['request_type'] = data['request_type'] ? data['request_type'].value : "";
    data['vehicle'] = data['vehicle'] ? data['vehicle'].value : "";

    if (edit) {
      editData(data);
    } else {
      addData(data, true);
    }

    setModal(false);
    setTimeout(() => {
      getData(setData);
    }, 500);
  }

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
    setSelects([]);
  }

  const editObj = (id) => {
    let aux = null;
    filteredItems.forEach(element => {
      if (element.id === id) {
        aux = element;
      }
    });
    setModal(true);
    setEdit(aux);
    setTitleModal('Edit Client');
  }

  const deleteObj = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        let aux = null;
        filteredItems.forEach(element => {
          if (element.id === id) {
            aux = element;
          }
        });
        deleteData(aux);
        Swal.fire({
          title: 'Deleted!',
          text: 'Has been removed successfully.',
          icon: 'success',
          confirmButtonColor: '#173d38',
        })
        setTimeout(() => {
          setModal(false);
          getData(setData);
          handleClearRows();
        }, 1000)
      }
    })
  }

  const filteredItems = data.rows.filter(
    item => item.client && item.client.toLowerCase().includes(filterText.toLowerCase()) ||
      item.owner && item.owner.toLowerCase().includes(filterText.toLowerCase()) ||
      item.organisation && item.organisation.toLowerCase().includes(filterText.toLowerCase()) ||
      item.status && item.status.toLowerCase().includes(filterText.toLowerCase()) ||
      item.organisation && item.organisation.toLowerCase().includes(filterText.toLowerCase()) ||
      item.email && item.email.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <h5 className='search-label'>Search</h5>
        <Input onChange={e => setFilterText(e.target.value)} filtertext={filterText} />
      </>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="content">
      <div className="d-flex justify-content-between flex-row-reverse ">
        <div className="gap-2 m-3">
          <Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => {
            setExpand(!expand);
          }} >{expand ? "Expand" : "Compress"}</Button>
          <Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => {
            setModal(true);
            setTitleModal('Add Client');
            setEdit(null);
          }} >Add Client</Button>
          <Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => {
            if (selects.length === 1) {
              setModal(true);
              setEdit(selects[0]);
              setTitleModal('Edit Client');
              handleClearRows();
            } else {
              Swal.fire({
                title: 'Warning!',
                text: 'You must select a client to edit, (ONLY ONE)',
                icon: 'warning',
                confirmButtonColor: "#173d38",

            })
            }
          }} >Edit Selected</Button>
          <Button className="color-green btn btn-outline-secondary p-2 mx-2" onClick={() => {
            if (selects.length > 0) {
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                  if (selects.length >= 1) {
                    selects.forEach(element => {
                      deleteData(element);
                    });
                    Swal.fire({
                      title: 'Deleted!',
                      text: 'Has been removed successfully.',
                      icon: 'success',
                      confirmButtonColor: '#173d38',
                    })
                    setTimeout(() => {
                      setModal(false);
                      getData(setData);
                      handleClearRows();
                    }, 1000)
                  }
                }
              })
            } else {
              Swal.fire({
                title: 'Warning!',
                text: 'You must select something to be able to delete',
                icon: 'warning',
                confirmButtonColor: "#173d38",
              })
            }
          }}>
            Delete Selected</Button>
        </div>
        
        {/** This must hidden in Prod */}
        <div className='row px-3'> 
          <div className='col-md-4'>
            <div className='row'>
              {/* <div className='col-12'>
                <ExcelExport ref={_export}>
                  <Button className="color-green btn btn-outline-secondary mb-2 w-100" onClick={excelExport}
                  >
                    Export to Excel
                  </Button>
                </ExcelExport>
              </div>
              <div className='col-12'>
                <Form.Group controlId="formFile" className="w-100">
                  <Form.Control type="file" onChange={
                    (e) => {
                      fileHandler(e, setData);
                      setTimeout(() => {
                        getData(setData);
                      }, 1000);
                    }
                  } />
                </Form.Group>
              </div> */}
            </div>
          </div>
        </div>

      </div>
      <div className="m-3">
        <DataTable
          columns={columns(expand, editObj, deleteObj)}
          data={filteredItems}
          selectableRows
          onSelectedRowsChange={handleChange}
          pagination
          clearSelectedRows={toggledClearRows}
          defaultSortFieldId={9}
          defaultSortAsc={false}
          progressPending={pending}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
        />
        <ModalC
          show={modal}
          onHide={() => setModal(false)}>
          <FormClient title={titleModal} lastData={edit} textButton={titleModal} action={action} setModal={setModal}></FormClient>
        </ModalC>
      </div>
    </div>
  );
}

export default TableClient;
